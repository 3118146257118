'use strict';

/**
 * Used on every page
 * @class MobileMenu
 */
class MobileMenu {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  constructor(element) {
    this.$element = $(element);
    this.$cornerButton = $('#navigation-bar .links a.menu');
    this.visible = false;

    this.bind();
  }

  /**
   * Bind the event handlers
   */
  bind() {
    $('.mobile-menu-link').click(this.toggleMobileMenu.bind(this));
  }

  toggleMobileMenu() {
    console.log(this.$cornerButton);

    if (this.visible) {
      this.$element.removeClass('visible');
      this.$cornerButton.removeClass('open');
    } else {
      this.$element.addClass('visible');
      this.$cornerButton.addClass('open');
    }
    this.visible = !this.visible;
  }
}

// Initialise any content switchers
$(document).ready(() => $('#mobile-menu').each((index, element) => {
  return new MobileMenu(element);
}));
