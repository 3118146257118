'use strict';

/**
 * Used on the front page
 * @class Navigation
 */
class Navigation {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  constructor(element) {
    this.$element = $(element).find('.only-on-computer ul');
    this.searchOpen = false;

    this.bind();
  }

  /**
   * Bind the event handlers
   */
  bind() {
    this.$element.find('a.search').on('click', this.search.bind(this));

    this.$element.find('.menu-item-has-children').on('mouseover', this.showDropdown.bind(this));
    this.$element.find('.menu-item-has-children').on('mouseleave', this.hideDropdown.bind(this));
  }

  showDropdown(event) {
    const $target = $(event.delegateTarget);
    $target.children('ul').addClass('visible');
    $target.children('a').addClass('highlight');
  }

  hideDropdown(event) {
    const $target = $(event.delegateTarget);
    $target.children('ul').removeClass('visible');
    $target.children('a').removeClass('highlight');
  }

  search(event) {
    event.preventDefault();
    if (this.searchOpen) {
      this.$element.find('li.search span').removeClass('visible').addClass('invisible');
      this.$element.find('li:not(.search)').removeClass('invisible').addClass('visible');
    } else {
      console.log('here');
      this.$element.find('li:not(.search)').removeClass('visible').addClass('invisible');
      this.$element.find('li.search span').removeClass('invisible').addClass('visible');
    }
    this.searchOpen = !this.searchOpen;
  }
}

// Initialise any content switchers
$(document).ready(() => $('#navigation-bar').each((index, element) => {
  return new Navigation(element);
}));
