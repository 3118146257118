'use strict';

/**
 * Used on the front page
 * @class CrossFade
 */
class CrossFade {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  constructor(element) {
    this.$element = $(element);
    this.$slidesContainer = this.$element.children('.slides');
    this.$slides = this.$slidesContainer.children('.slide');
    this.$dots = this.$element.find('.dots ul');
    this.$nextButton = this.$element.find('.next.button');
    this.$previousButton = this.$element.find('.previous.button');
    this.setSlideHeight = this.$element.attr('data-set-slide-height') ? this.$element.attr('data-set-slide-height') == 'true' : false;

    this.calculateHeight();
    this.$slides.each((index, slide) => {
      this.hideSlide($(slide));
      this.$dots.append('<li><a href="#" data-slide="' + index + '"></a></li>');
    });

    this.showSlide($(this.$slides.get(0)));

    this.bind();
    this.startTimer();
  }
  
  /**
   * Bind the event handlers
   */
   bind() {
    $(window).resize(this.calculateHeight.bind(this));

    this.$slidesContainer.find('img, iframe')
      .on('load', this.calculateHeight.bind(this));

    this.$dots.find('li a').on('click', this.click.bind(this));
    this.$nextButton.on('click', this.nextSlide.bind(this));
    this.$previousButton.on('click', this.previousSlide.bind(this));

    this.$element
      .mouseenter(this.stopTimer.bind(this))
      .mouseleave(this.startTimer.bind(this));
  }

  calculateHeight() {
    let maxHeight = 0;

    this.$slides.each((index, slide) => {
      const $slide = $(slide);
      const $innerContainer = $slide.children('.content-container');

      let height = 0;
      if ($innerContainer.length > 0) {
        height = $innerContainer.outerHeight(true);
      } else {
        height = $slide.outerHeight(true);
      }

      $slide.attr('data-slide', index);
      if (height > maxHeight) maxHeight = height;
    });

    if (maxHeight > 0) {
      this.$slidesContainer.outerHeight(maxHeight);
      if (this.setSlideHeight) this.$slides.outerHeight(maxHeight);
    }
  }

  /**
   * Change the visible slide on click
   */
  click(event) {
    event.preventDefault();
    const $link = $(event.target);
    const slideNum = $link.attr('data-slide');

    if (slideNum === this.$currentSlide.attr('data-slide')) return;

    this.hideSlide(this.$currentSlide);
    this.showSlide($(this.$slides.get(slideNum)));
  }

  /**
   * Hide a slide
   * @param $slide
   */
  hideSlide($slide) {
    $slide.removeClass('visible').addClass('invisible');
    const slideNum = $slide.attr('data-slide');
    this.$dots.find('li a[data-slide=' + slideNum + ']').removeClass('selected');
  }

  /**
   * Make a slide visible
   * @param $slide
   */
  showSlide($slide) {
    $slide.removeClass('invisible').addClass('visible');
    const slideNum = $slide.attr('data-slide');
    this.$dots.find('li a[data-slide=' + slideNum + ']').addClass('selected');
    this.$currentSlide = $slide;
  }

  /**
   * Make the next slide visible (and wrap if necessary)
   */
  nextSlide() {
    const slideNum = parseInt(this.$currentSlide.attr('data-slide'));
    const nextSlideNum = (slideNum === this.$slides.length - 1) ? 0 : slideNum + 1;
    this.hideSlide(this.$currentSlide);
    this.showSlide($(this.$slides.get(nextSlideNum)));
  }

  /**
   * Make the previous slide visible (and wrap if necessary)
   */
  previousSlide() {
    const slideNum = parseInt(this.$currentSlide.attr('data-slide'));
    const nextSlideNum = (slideNum === 0) ? this.$slides.length - 1 : slideNum - 1;
    this.hideSlide(this.$currentSlide);
    this.showSlide($(this.$slides.get(nextSlideNum)));
  }

  /**
   * Set up a 6s interval to scroll through the images
   */
  startTimer() {
    this.stopTimer();
    this.interval = setInterval(this.nextSlide.bind(this), 6000);
  }

  /**
   * Stop the transition interval
   */
  stopTimer() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
}

// Initialise any content switchers
$(document).ready(() => $('.cross-fade').each((index, element) => {
  return new CrossFade(element);
}));
