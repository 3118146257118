'use strict';

/**
 * Used on the front page
 * @class ContentSwitcher
 */
class ContentSwitcher {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  constructor(element) {
    this.$element = $(element);
    this.$navigation = this.$element.children('.navigation').find('ul');
    this.$contentContainer = this.$element.children('.content-container');
    this.$slides = this.$contentContainer.children('div');
    this.$accordion = this.$element.children('.accordion');
    this.accordion = this.$accordion.length !== 0;
    this.navigation = this.$navigation.length !== 0;
    this.$accordionSlides = this.$accordion.find('div > .content');

    if (this.navigation) {
      let maxHeight = 0;
      this.$slides.each((index, slide) => {
        const $slide = $(slide);
        const height = $slide.outerHeight(true);
        if (height > maxHeight) maxHeight = height;
        this.hideSlide($(slide));
      });
      this.$contentContainer.outerHeight(maxHeight);

      this.showSlide($(this.$slides.get(0)));
    } else {
      this.$accordionSlides.each((index, slide) => {
        this.hideAccordionSlide($(slide).parent());
      });
      const $slide = $(this.$accordionSlides.get(0)).parent();
      this.showAccordionSlide($slide);
      this.$currentSlide = $slide;
    }

    this.bind();
  }

  /**
   * Bind the event handlers
   */
  bind() {
    if (this.navigation) {
    this.$navigation.find('li a').on('click', this.click.bind(this));
    }

    if (this.accordion) {
      this.$accordion.find('> div > a').on('click', this.click.bind(this));
    }
  }

  /**
   * Change the visible slide on click
   */
  click(event) {
    event.preventDefault();
    const $link = $(event.target);
    const slideNum = $link.attr('data-slide');
    
    if (slideNum === this.$currentSlide.attr('data-slide')) return;

    if (this.navigation) {
      this.hideSlide(this.$currentSlide);
      this.showSlide(this.$contentContainer.find('div[data-slide=' + slideNum + ']'));
    } else if (this.accordion) {
      const $slide = this.$accordion.find('div[data-slide=' + slideNum +']');
      this.hideAccordionSlide(this.$currentSlide);
      this.showAccordionSlide($slide);
      this.$currentSlide = $slide;
    }
  }

  /**
   * Hide a slide
   * @param $slide
     */
  hideSlide($slide) {
    $slide.removeClass('visible').addClass('invisible');
    const slideNum = $slide.attr('data-slide');
    this.$navigation.find('li a[data-slide=' + slideNum + ']').removeClass('selected');

    if (this.accordion) {
      this.hideAccordionSlide(this.$accordion.find('div[data-slide=' + slideNum +']'));
    }
  }

  /**
   * Hide the accordion slide
   * @param $slide
     */
  hideAccordionSlide($slide) {
    $slide.children('.content').removeClass('visible').addClass('invisible');
    $slide.children('a').removeClass('selected');
  }

  /**
   * Make a slide visible
   * @param $slide
     */
  showSlide($slide) {
    $slide.removeClass('invisible').addClass('visible');
    const slideNum = $slide.attr('data-slide');
    this.$navigation.find('li a[data-slide=' + slideNum + ']').addClass('selected');
    this.$currentSlide = $slide;

    if (this.accordion) {
      this.showAccordionSlide(this.$accordion.find('div[data-slide=' + slideNum +']'));
    }
  }

  /**
   * Make an accordion slide visible
   * @param $slide
     */
  showAccordionSlide($slide) {
    $slide.children('.content').removeClass('invisible').addClass('visible');
    $slide.children('a').addClass('selected');
  }
}

// Initialise any content switchers
$(document).ready(() => $('.content-switcher').each((index, element) => {
  return new ContentSwitcher(element);
}));

/**
 * Used on the contact us page
 * @class ContactMap
 */
class ContactMap extends ContentSwitcher {
  constructor($element) {
    super($element);
  }

  /**
   * Change the visible slide on click
   */
  click(event) {
    super.click(event);
    const $link = $(event.target);

    const lat = $link.attr('data-latitude');
    const long = $link.attr('data-longitude');
    if (lat && long) {
      map.panTo(new google.maps.LatLng(lat, long));// eslint-disable-line no-undef
    }
  }
}
// Initialise any content switchers
$(document).ready(() => $('.contact-map').each((index, element) => {
  return new ContactMap(element);
}));

