'use strict';

/**
 * Used on the careers page
 * @class Accordion
 */

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Accordion = function () {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  function Accordion(element) {
    var _this = this;

    _classCallCheck(this, Accordion);

    this.$accordion = $(element);
    this.$accordionSlides = this.$accordion.children('div');

    this.$accordionSlides.each(function (index, slide) {
      _this.hideAccordionSlide($(slide));
    });
    var $slide = $(this.$accordionSlides.get(0));
    this.showAccordionSlide($slide);
    this.$currentSlide = $slide;

    this.bind();
  }

  /**
   * Bind the event handlers
   */


  _createClass(Accordion, [{
    key: 'bind',
    value: function bind() {
      this.$accordion.find('> div > a').on('click', this.click.bind(this));
    }

    /**
     * Change the visible slide on click
     */

  }, {
    key: 'click',
    value: function click(event) {
      event.preventDefault();
      var $link = $(event.target);
      var $slide = $link.parent();
      var targetClosed = $slide.hasClass('closed');

      this.hideAccordionSlide(this.$currentSlide);

      if (!$slide.is(this.$currentSlide) || targetClosed) {
        this.showAccordionSlide($slide);
      }

      this.$currentSlide = $slide;
    }

    /**
     * Hide the accordion slide
     * @param $slide
     */

  }, {
    key: 'hideAccordionSlide',
    value: function hideAccordionSlide($slide) {
      $slide.removeClass('open').addClass('closed');
    }

    /**
     * Make an accordion slide visible
     * @param $slide
     */

  }, {
    key: 'showAccordionSlide',
    value: function showAccordionSlide($slide) {
      $slide.removeClass('closed').addClass('open');
    }
  }]);

  return Accordion;
}();

// Initialise any content switchers


$(document).ready(function () {
  return $('.simple-accordion').each(function (index, element) {
    return new Accordion(element);
  });
});
'use strict';

/**
 * Used on the front page
 * @class CollapsibleSection
 */

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CollapsibleSection = function () {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  function CollapsibleSection(element) {
    _classCallCheck(this, CollapsibleSection);

    this.$element = $(element);
    this.$header = this.$element.children('.header');
    this.$content = this.$element.children('.content');
    this.visible = false;

    this.$element.removeClass('open').addClass('closed');

    this.bind();
  }

  /**
   * Bind the event handlers
   */


  _createClass(CollapsibleSection, [{
    key: 'bind',
    value: function bind() {
      this.$header.on('click', this.click.bind(this));
    }

    /**
     * Change the visible slide on click
     */

  }, {
    key: 'click',
    value: function click(event) {
      event.preventDefault();
      if (this.visible) {
        this.$element.removeClass('open').addClass('closed');
      } else {
        this.$element.removeClass('closed').addClass('open');
      }
      this.visible = !this.visible;
    }
  }]);

  return CollapsibleSection;
}();

// Initialise any content switchers


$(document).ready(function () {
  return $('.collapsible').each(function (index, element) {
    return new CollapsibleSection(element);
  });
});
'use strict';

/**
 * Used on the front page
 * @class ContentSwitcher
 */

var _get = function get(object, property, receiver) { if (object === null) object = Function.prototype; var desc = Object.getOwnPropertyDescriptor(object, property); if (desc === undefined) { var parent = Object.getPrototypeOf(object); if (parent === null) { return undefined; } else { return get(parent, property, receiver); } } else if ("value" in desc) { return desc.value; } else { var getter = desc.get; if (getter === undefined) { return undefined; } return getter.call(receiver); } };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var ContentSwitcher = function () {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  function ContentSwitcher(element) {
    var _this = this;

    _classCallCheck(this, ContentSwitcher);

    this.$element = $(element);
    this.$navigation = this.$element.children('.navigation').find('ul');
    this.$contentContainer = this.$element.children('.content-container');
    this.$slides = this.$contentContainer.children('div');
    this.$accordion = this.$element.children('.accordion');
    this.accordion = this.$accordion.length !== 0;
    this.navigation = this.$navigation.length !== 0;
    this.$accordionSlides = this.$accordion.find('div > .content');

    if (this.navigation) {
      (function () {
        var maxHeight = 0;
        _this.$slides.each(function (index, slide) {
          var $slide = $(slide);
          var height = $slide.outerHeight(true);
          if (height > maxHeight) maxHeight = height;
          _this.hideSlide($(slide));
        });
        _this.$contentContainer.outerHeight(maxHeight);

        _this.showSlide($(_this.$slides.get(0)));
      })();
    } else {
      this.$accordionSlides.each(function (index, slide) {
        _this.hideAccordionSlide($(slide).parent());
      });
      var $slide = $(this.$accordionSlides.get(0)).parent();
      this.showAccordionSlide($slide);
      this.$currentSlide = $slide;
    }

    this.bind();
  }

  /**
   * Bind the event handlers
   */


  _createClass(ContentSwitcher, [{
    key: 'bind',
    value: function bind() {
      if (this.navigation) {
        this.$navigation.find('li a').on('click', this.click.bind(this));
      }

      if (this.accordion) {
        this.$accordion.find('> div > a').on('click', this.click.bind(this));
      }
    }

    /**
     * Change the visible slide on click
     */

  }, {
    key: 'click',
    value: function click(event) {
      event.preventDefault();
      var $link = $(event.target);
      var slideNum = $link.attr('data-slide');

      if (slideNum === this.$currentSlide.attr('data-slide')) return;

      if (this.navigation) {
        this.hideSlide(this.$currentSlide);
        this.showSlide(this.$contentContainer.find('div[data-slide=' + slideNum + ']'));
      } else if (this.accordion) {
        var $slide = this.$accordion.find('div[data-slide=' + slideNum + ']');
        this.hideAccordionSlide(this.$currentSlide);
        this.showAccordionSlide($slide);
        this.$currentSlide = $slide;
      }
    }

    /**
     * Hide a slide
     * @param $slide
       */

  }, {
    key: 'hideSlide',
    value: function hideSlide($slide) {
      $slide.removeClass('visible').addClass('invisible');
      var slideNum = $slide.attr('data-slide');
      this.$navigation.find('li a[data-slide=' + slideNum + ']').removeClass('selected');

      if (this.accordion) {
        this.hideAccordionSlide(this.$accordion.find('div[data-slide=' + slideNum + ']'));
      }
    }

    /**
     * Hide the accordion slide
     * @param $slide
       */

  }, {
    key: 'hideAccordionSlide',
    value: function hideAccordionSlide($slide) {
      $slide.children('.content').removeClass('visible').addClass('invisible');
      $slide.children('a').removeClass('selected');
    }

    /**
     * Make a slide visible
     * @param $slide
       */

  }, {
    key: 'showSlide',
    value: function showSlide($slide) {
      $slide.removeClass('invisible').addClass('visible');
      var slideNum = $slide.attr('data-slide');
      this.$navigation.find('li a[data-slide=' + slideNum + ']').addClass('selected');
      this.$currentSlide = $slide;

      if (this.accordion) {
        this.showAccordionSlide(this.$accordion.find('div[data-slide=' + slideNum + ']'));
      }
    }

    /**
     * Make an accordion slide visible
     * @param $slide
       */

  }, {
    key: 'showAccordionSlide',
    value: function showAccordionSlide($slide) {
      $slide.children('.content').removeClass('invisible').addClass('visible');
      $slide.children('a').addClass('selected');
    }
  }]);

  return ContentSwitcher;
}();

// Initialise any content switchers


$(document).ready(function () {
  return $('.content-switcher').each(function (index, element) {
    return new ContentSwitcher(element);
  });
});

/**
 * Used on the contact us page
 * @class ContactMap
 */

var ContactMap = function (_ContentSwitcher) {
  _inherits(ContactMap, _ContentSwitcher);

  function ContactMap($element) {
    _classCallCheck(this, ContactMap);

    return _possibleConstructorReturn(this, (ContactMap.__proto__ || Object.getPrototypeOf(ContactMap)).call(this, $element));
  }

  /**
   * Change the visible slide on click
   */


  _createClass(ContactMap, [{
    key: 'click',
    value: function click(event) {
      _get(ContactMap.prototype.__proto__ || Object.getPrototypeOf(ContactMap.prototype), 'click', this).call(this, event);
      var $link = $(event.target);

      var lat = $link.attr('data-latitude');
      var long = $link.attr('data-longitude');
      if (lat && long) {
        map.panTo(new google.maps.LatLng(lat, long)); // eslint-disable-line no-undef
      }
    }
  }]);

  return ContactMap;
}(ContentSwitcher);
// Initialise any content switchers


$(document).ready(function () {
  return $('.contact-map').each(function (index, element) {
    return new ContactMap(element);
  });
});
'use strict';

/**
 * Used on the front page
 * @class CrossFade
 */

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CrossFade = function () {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  function CrossFade(element) {
    var _this = this;

    _classCallCheck(this, CrossFade);

    this.$element = $(element);
    this.$slidesContainer = this.$element.children('.slides');
    this.$slides = this.$slidesContainer.children('.slide');
    this.$dots = this.$element.find('.dots ul');
    this.$nextButton = this.$element.find('.next.button');
    this.$previousButton = this.$element.find('.previous.button');
    this.setSlideHeight = this.$element.attr('data-set-slide-height') ? this.$element.attr('data-set-slide-height') == 'true' : false;

    this.calculateHeight();
    this.$slides.each(function (index, slide) {
      _this.hideSlide($(slide));
      _this.$dots.append('<li><a href="#" data-slide="' + index + '"></a></li>');
    });

    this.showSlide($(this.$slides.get(0)));

    this.bind();
    this.startTimer();
  }

  /**
   * Bind the event handlers
   */


  _createClass(CrossFade, [{
    key: 'bind',
    value: function bind() {
      $(window).resize(this.calculateHeight.bind(this));

      this.$slidesContainer.find('img, iframe').on('load', this.calculateHeight.bind(this));

      this.$dots.find('li a').on('click', this.click.bind(this));
      this.$nextButton.on('click', this.nextSlide.bind(this));
      this.$previousButton.on('click', this.previousSlide.bind(this));

      this.$element.mouseenter(this.stopTimer.bind(this)).mouseleave(this.startTimer.bind(this));
    }
  }, {
    key: 'calculateHeight',
    value: function calculateHeight() {
      var maxHeight = 0;

      this.$slides.each(function (index, slide) {
        var $slide = $(slide);
        var $innerContainer = $slide.children('.content-container');

        var height = 0;
        if ($innerContainer.length > 0) {
          height = $innerContainer.outerHeight(true);
        } else {
          height = $slide.outerHeight(true);
        }

        $slide.attr('data-slide', index);
        if (height > maxHeight) maxHeight = height;
      });

      if (maxHeight > 0) {
        this.$slidesContainer.outerHeight(maxHeight);
        if (this.setSlideHeight) this.$slides.outerHeight(maxHeight);
      }
    }

    /**
     * Change the visible slide on click
     */

  }, {
    key: 'click',
    value: function click(event) {
      event.preventDefault();
      var $link = $(event.target);
      var slideNum = $link.attr('data-slide');

      if (slideNum === this.$currentSlide.attr('data-slide')) return;

      this.hideSlide(this.$currentSlide);
      this.showSlide($(this.$slides.get(slideNum)));
    }

    /**
     * Hide a slide
     * @param $slide
     */

  }, {
    key: 'hideSlide',
    value: function hideSlide($slide) {
      $slide.removeClass('visible').addClass('invisible');
      var slideNum = $slide.attr('data-slide');
      this.$dots.find('li a[data-slide=' + slideNum + ']').removeClass('selected');
    }

    /**
     * Make a slide visible
     * @param $slide
     */

  }, {
    key: 'showSlide',
    value: function showSlide($slide) {
      $slide.removeClass('invisible').addClass('visible');
      var slideNum = $slide.attr('data-slide');
      this.$dots.find('li a[data-slide=' + slideNum + ']').addClass('selected');
      this.$currentSlide = $slide;
    }

    /**
     * Make the next slide visible (and wrap if necessary)
     */

  }, {
    key: 'nextSlide',
    value: function nextSlide() {
      var slideNum = parseInt(this.$currentSlide.attr('data-slide'));
      var nextSlideNum = slideNum === this.$slides.length - 1 ? 0 : slideNum + 1;
      this.hideSlide(this.$currentSlide);
      this.showSlide($(this.$slides.get(nextSlideNum)));
    }

    /**
     * Make the previous slide visible (and wrap if necessary)
     */

  }, {
    key: 'previousSlide',
    value: function previousSlide() {
      var slideNum = parseInt(this.$currentSlide.attr('data-slide'));
      var nextSlideNum = slideNum === 0 ? this.$slides.length - 1 : slideNum - 1;
      this.hideSlide(this.$currentSlide);
      this.showSlide($(this.$slides.get(nextSlideNum)));
    }

    /**
     * Set up a 6s interval to scroll through the images
     */

  }, {
    key: 'startTimer',
    value: function startTimer() {
      this.stopTimer();
      this.interval = setInterval(this.nextSlide.bind(this), 6000);
    }

    /**
     * Stop the transition interval
     */

  }, {
    key: 'stopTimer',
    value: function stopTimer() {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
    }
  }]);

  return CrossFade;
}();

// Initialise any content switchers


$(document).ready(function () {
  return $('.cross-fade').each(function (index, element) {
    return new CrossFade(element);
  });
});
"use strict";

/**
 * Example JS file that will be auto-gulped
 */

$(function () {});
'use strict';

$(document).ready(function () {
  $('#mobile-menu li.menu-item-has-children').click(function (event) {
    event.preventDefault();
    $(this).find('ul').toggle();
  });
});
'use strict';

/**
 * Used on every page
 * @class MobileMenu
 */

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var MobileMenu = function () {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  function MobileMenu(element) {
    _classCallCheck(this, MobileMenu);

    this.$element = $(element);
    this.$cornerButton = $('#navigation-bar .links a.menu');
    this.visible = false;

    this.bind();
  }

  /**
   * Bind the event handlers
   */


  _createClass(MobileMenu, [{
    key: 'bind',
    value: function bind() {
      $('.mobile-menu-link').click(this.toggleMobileMenu.bind(this));
    }
  }, {
    key: 'toggleMobileMenu',
    value: function toggleMobileMenu() {
      console.log(this.$cornerButton);

      if (this.visible) {
        this.$element.removeClass('visible');
        this.$cornerButton.removeClass('open');
      } else {
        this.$element.addClass('visible');
        this.$cornerButton.addClass('open');
      }
      this.visible = !this.visible;
    }
  }]);

  return MobileMenu;
}();

// Initialise any content switchers


$(document).ready(function () {
  return $('#mobile-menu').each(function (index, element) {
    return new MobileMenu(element);
  });
});
'use strict';

/**
 * Used on the front page
 * @class Navigation
 */

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var Navigation = function () {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  function Navigation(element) {
    _classCallCheck(this, Navigation);

    this.$element = $(element).find('.only-on-computer ul');
    this.searchOpen = false;

    this.bind();
  }

  /**
   * Bind the event handlers
   */


  _createClass(Navigation, [{
    key: 'bind',
    value: function bind() {
      this.$element.find('a.search').on('click', this.search.bind(this));

      this.$element.find('.menu-item-has-children').on('mouseover', this.showDropdown.bind(this));
      this.$element.find('.menu-item-has-children').on('mouseleave', this.hideDropdown.bind(this));
    }
  }, {
    key: 'showDropdown',
    value: function showDropdown(event) {
      var $target = $(event.delegateTarget);
      $target.children('ul').addClass('visible');
      $target.children('a').addClass('highlight');
    }
  }, {
    key: 'hideDropdown',
    value: function hideDropdown(event) {
      var $target = $(event.delegateTarget);
      $target.children('ul').removeClass('visible');
      $target.children('a').removeClass('highlight');
    }
  }, {
    key: 'search',
    value: function search(event) {
      event.preventDefault();
      if (this.searchOpen) {
        this.$element.find('li.search span').removeClass('visible').addClass('invisible');
        this.$element.find('li:not(.search)').removeClass('invisible').addClass('visible');
      } else {
        console.log('here');
        this.$element.find('li:not(.search)').removeClass('visible').addClass('invisible');
        this.$element.find('li.search span').removeClass('invisible').addClass('visible');
      }
      this.searchOpen = !this.searchOpen;
    }
  }]);

  return Navigation;
}();

// Initialise any content switchers


$(document).ready(function () {
  return $('#navigation-bar').each(function (index, element) {
    return new Navigation(element);
  });
});
'use strict';

// Expanding sidebar menu
$(document).ready(function () {
  $('li.cat-item ul').parent().children('a').click(function (event) {
    event.preventDefault();
    $(this).parent().find('ul').toggle();
  });
});