'use strict';

/**
 * Used on the careers page
 * @class Accordion
 */
class Accordion {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  constructor(element) {
    this.$accordion = $(element);
    this.$accordionSlides = this.$accordion.children('div');

    this.$accordionSlides.each((index, slide) => {
      this.hideAccordionSlide($(slide));
    });
    const $slide = $(this.$accordionSlides.get(0));
    this.showAccordionSlide($slide);
    this.$currentSlide = $slide;

    this.bind();
  }

  /**
   * Bind the event handlers
   */
  bind() {
    this.$accordion.find('> div > a').on('click', this.click.bind(this));
  }

  /**
   * Change the visible slide on click
   */
  click(event) {
    event.preventDefault();
    const $link = $(event.target);
    const $slide = $link.parent();
    const targetClosed = $slide.hasClass('closed');

    this.hideAccordionSlide(this.$currentSlide);

    if (!$slide.is(this.$currentSlide) || targetClosed) {
      this.showAccordionSlide($slide);
    }

    this.$currentSlide = $slide;
  }

  /**
   * Hide the accordion slide
   * @param $slide
   */
  hideAccordionSlide($slide) {
    $slide.removeClass('open').addClass('closed');
  }

  /**
   * Make an accordion slide visible
   * @param $slide
   */
  showAccordionSlide($slide) {
    $slide.removeClass('closed').addClass('open');
  }
}

// Initialise any content switchers
$(document).ready(() => $('.simple-accordion').each((index, element) => {
  return new Accordion(element);
}));
