'use strict';

/**
 * Used on the front page
 * @class CollapsibleSection
 */
class CollapsibleSection {
  /**
   * Set up classes on the carousel slides
   * @param element
   */
  constructor(element) {
    this.$element = $(element);
    this.$header = this.$element.children('.header');
    this.$content = this.$element.children('.content');
    this.visible = false;

    this.$element.removeClass('open').addClass('closed');

    this.bind();
  }

  /**
   * Bind the event handlers
   */
  bind() {
    this.$header.on('click', this.click.bind(this));
  }

  /**
   * Change the visible slide on click
   */
  click(event) {
    event.preventDefault();
    if (this.visible) {
      this.$element.removeClass('open').addClass('closed');
    } else {
      this.$element.removeClass('closed').addClass('open');
    }
    this.visible = !this.visible;
  }
}

// Initialise any content switchers
$(document).ready(() => $('.collapsible').each((index, element) => {
  return new CollapsibleSection(element);
}));
